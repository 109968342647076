.wrapper {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signupContainer {
    padding: 10px;
    border: 1px black solid;
    width: 500px;
    border-radius: 10px;
    box-shadow: rgb(96, 104, 108) 0px 4px 2px -2px;
}

.signupBtn {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid green;
    background-color: transparent;
    color: green;
}

.signupBtn:hover {
    background-color: green;
    color: white;
}

#signupFrom {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#signupFrom>input {
    height: 30px;
}

.notice {
    color: red;
}


.login-link {
    display: inline;
    color: #FAA85F;
}

@media (max-width: 480px) {
    .signupContainer {
        width: 100%;
    }
}