.wrapper {
    display: flex;
    flex-direction: flex;
}

.header-content {
    justify-items: center;
}

#coupon-grid {
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
    width: 100%;
    height: 100%;
    background-color: white;
}

@media (max-width: 480px) {
    .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header-content>h1 {
        font-size: larger;
    }

    #coupon-grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}