#navbar {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: rgba(156, 213, 240, 0.63);
    box-shadow: 0 4px 2px -2px gray;
    overflow: hidden;
}

.logo-container {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.left-continer {
    padding-top: 8px;
    margin-left: 30px;
    font-size: 25px;
}

a,
a:visited {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #F8CF26;
}

#addIcon {
    position: relative;
    top: 2px;
}

.searchTab {
    margin-right: 100px;
    display: flex;
    width: 80%;
}

.searchTab>input {
    height: 38px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    font-size: 20px;
}

.user-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.createBtn {
    height: 30px;
    border-radius: 4px;
    border: 1px solid green;
    background-color: transparent;
    color: green;
}

.createBtn:hover {
    background-color: green;
    color: white;
}

.accountBtn {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid green;
    background-color: transparent;
    color: green;
}

.accountBtn:hover {
    background-color: green;
    color: white;
}

.loginBtn {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid green;
    background-color: transparent;
    color: green;
}

.loginBtn:hover {
    background-color: green;
    color: white;
}


.signoutBtn {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid red;
    background-color: transparent;
    color: red;
}

.signoutBtn:hover {
    background-color: red;
    color: white;
}

.myAccount {
    display: flex;
    gap: 8px;
}

@media (max-width: 480px) {
    #navbar {
        width: 100%;
    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .left-continer>a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .user-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2px;
        padding: 8px
    }

    .loginBtn {
        width: 100px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid green;
        background-color: transparent;
        color: green;
    }
}