.wrapper-grid{
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(88, 87, 87);
    border-radius: 5px;
    margin: 10px 8px 10px 8px;
    width: 300px;
    height: auto;
    box-shadow: rgb(96, 104, 108) 0px 4px 2px -2px;
}

.right-content{
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
}

.right-content > input{
  height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.interaction-content{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.rating{
    display: flex;
    width: 50%;
    font-size: 15px;
}

.commentIcon{
    font-size: 15px;
}

.comments{
    width: 50%;
}

.commentCount{
    display: inline;
    position: relative;
    bottom: 3px;
}
.date-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}
.date-content > .popOut{
    padding-bottom: 2px;
}


.desc-content{
    display: inline-block;
    width: 290px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.image-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}