.wrapper{
    display: flex;
    flex-direction: column;
}

#createForm{
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap:10px;
    border: 1px black solid;
    border-radius: 10px;
    box-shadow: rgb(96, 104, 108) 0px 4px 2px -2px;
}
#createForm > .postPhoto{
    height: 20px;
}

#createForm > input{
   width: 300px;
}

#createForm > textarea{
    min-height: 80px;
    resize: vertical;
 }

.submitBtn{
    width: auto;
    height: 30px;
    border-radius: 4px;
    border: 1px solid green;
    background-color: transparent;
    color: green;
}

.submitBtn:hover {
    background-color: green;
    color: white;
}

.notice-success{
    margin: 8px;
    padding: 8px;
    border: 1px solid green;
    background-color: rgba(166, 236, 166, 0.856);
    border-radius: 2px;
    color: green;
}

.notice-error{
    margin: 8px;
    padding: 8px;
    border: 1px solid red;
    background-color: pink;
    border-radius: 2px;
    color: red;
}